const LOCAL_IMAGES={
    IMAGE1:require('../../assets/images/image2.webp'),
   
    monsoonlogo:require('../../assets/images/monsoonlogo.png'),
    skincoIcon:require('../../assets/images/Skinco.png'),
    kerastaseIcon:require('../../assets/images/Kerastase.jpg'),
    thalgoIcon:require('../../assets/images/Thalgo.png'),
    argatinIcon:require('../../assets/images/Argatin.png'),
   




    aboutUs:require('../../assets/images/aboutus.jpg'),
    gallery:require('../../assets/images/gallery.webp'),
    gallery2:require('../../assets/images/gallery2.webp'),
    aboutuspage:require('../../assets/images/about_us_banner.png'),
    about:require('../../assets/images/about.png'),
    hairIcon:require('../../assets/images/hair_icon.png'),
    nailIcon:require('../../assets/images/nail_icon.png'),
    beautyIcon:require('../../assets/images/beautyicon.png'),
    makeupIcon:require('../../assets/images/makeup_icon.png'),

   quoteicon:require('../../assets/images/quote.png'),
  




   //brands banner 


   argatinIcon:require('../../assets/images/argatin.jpg'),
  springIcon:require('../../assets/images/spring.jpg'),
  skinIcon:require('../../assets/images/skinco.jpg'),
  thalgoIcon:require('../../assets/images/thalgo.jpg'),
  lorealIcon:require('../../assets/images/lorealbrand.png'),


  //





   //gallery

   monsoonsalon12:require('../../assets/images/monsoon12.jpg'),
   monsoonsalon13:require('../../assets/images/monsoon13.jpg'),
   monsoonsalon14:require('../../assets/images/monsoon14.jpg'),
   monsoonsalon15:require('../../assets/images/monsoon15.jpg'),
   monsoonsalon16:require('../../assets/images/monsoon16.jpg'),
   monsoonsalon17:require('../../assets/images/monsoon17.jpg'),
   monsoonsalon18:require('../../assets/images/monsoon18.jpg'),
   monsoonsalon19:require('../../assets/images/monsoon19.jpg'),
   monsoonsalon20:require('../../assets/images/monsoon20.jpg'),
   monsoonsalon21:require('../../assets/images/monsoon21.jpg'),
   monsoonsalon22:require('../../assets/images/monsoon22.jpg'),
   monsoonsalon23:require('../../assets/images/monsoon23.jpg'),
   monsoonsalon24:require('../../assets/images/monsoon24.jpg'),
   monsoonsalon25:require('../../assets/images/monsoon25.jpg'),
   monsoonsalon26:require('../../assets/images/monsoon26.jpg'),
   monsoonsalon27:require('../../assets/images/monsoon27.jpg'),


   



































    

}
export default LOCAL_IMAGES;